@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#root{
  height: 100vh;
  width: 100vw;
}


::-webkit-scrollbar {
  width: 4px;
  height: 4px; /* for horizontal scrollbars */
}

::-webkit-scrollbar-thumb {
  background-color: darkgray;
  border-radius: 1px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}
